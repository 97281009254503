import { Link } from '@remix-run/react';
import Vimeo from '@u-wave/react-vimeo';

import { ClientOnly } from 'remix-utils/client-only';

import { DeliveryCheckerChip } from '~/components/delivery-checker-chip';
import { cn } from '~/utils/css';

export type Banner = { className?: string };

export function Banner({ className }: Banner) {
  return (
    <div
      className={cn(
        'relative overflow-hidden border-b bg-white pb-4 dark:border-zinc-700 dark:bg-zinc-950',
        className
      )}>
      <div className='relative pb-16 pt-6 sm:pb-24 lg:pb-32'>
        <main className='mx-auto mt-16 max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32'>
          <div className='lg:grid lg:grid-cols-12 lg:gap-12'>
            <div className='sm:text-center md:mx-auto md:max-w-2xl lg:col-span-5 lg:text-left'>
              <div className='mb-8 flex sm:justify-center lg:justify-start'>
                <DeliveryCheckerChip />
              </div>

              <h1>
                <span className='mt-1 block text-4xl font-bold tracking-tight sm:text-5xl xl:text-6xl'>
                  <span className='block text-gray-900 dark:text-white'>
                    Ready to get
                  </span>

                  <span className='block text-gray-900 dark:text-white'>
                    into shape?
                  </span>
                </span>
              </h1>

              <p className='mt-3 text-base text-zinc-500 dark:text-zinc-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                Meal Preps. Fresh, full of flavour and delivered to your door in
                just a few clicks.
              </p>

              <div className='mt-8 sm:mx-auto sm:max-w-lg sm:text-center lg:mx-0 lg:text-left'>
                <div className='space-y-4 sm:mx-auto sm:inline-grid sm:gap-5 sm:space-y-0'>
                  <Link
                    to='/order-now'
                    className='flex items-center justify-center rounded-md border border-transparent bg-brand-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-brand-700 dark:bg-brand-700 dark:hover:bg-brand-600 sm:px-8'>
                    Order now
                  </Link>
                </div>
              </div>
            </div>

            <div className='relative mt-12 sm:mx-auto sm:max-w-lg lg:col-span-7 lg:mx-0 lg:mt-0 lg:flex lg:max-w-none lg:items-center'>
              <div className='relative mx-auto w-full overflow-clip rounded-lg bg-black shadow-lg lg:max-w-2xl'>
                <span className='sr-only'>Watch our video to learn more</span>

                <div className='relative'>
                  <ClientOnly>
                    {() => (
                      <Vimeo
                        className='relative'
                        video='860653677'
                        showTitle={false}
                        showPortrait={false}
                        showByline={false}
                        responsive={true}
                        controls={true}
                        pip={true}
                        onError={() => null}
                      />
                    )}
                  </ClientOnly>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
